.todo-adder {
    display: flex;
    margin: 0px 30%;
    align-items: center;
}

.todo-input {
    font-size: 20px;
}

.todo-label {
    font-size: 20px;
    padding-left: 5px;
}

.todo-input[type="checkbox"] {
    height: 30px;
    width: 30px;
}

.todo-header {
    font-size: 25px;
    margin-bottom: 20px;
    margin-top: 20px;
}
